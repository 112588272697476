<template>
  <div
    class="absolute bottom-5 top-5 left-5 z-30 bg-white shadow flex flex-col w-module p-4"
  >
    <div class="flex w-full items-center justify-between mb-4">
      <div
        class="text-darkblue text-xl font-bold whitespace-no-wrap font-SourceSansPro"
      >
        {{
          title === 1
            ? $t('objects_locator_create')
            : $t('objects_locator_change')
        }}
      </div>
      <div class="flex justify-end">
        <i
          class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
          @click="$emit('close')"
        ></i>
      </div>
    </div>
    <el-form ref="locatorForm" :model="locator" :rules="rules">
      <div class="mt-5">
        <p
          class="mb-2 text-annotationColor font-SourceSansPro text-sm font-semibold"
        >
          *{{ $t('object') }}
        </p>
        <el-form-item prop="unit">
          <el-select
            filterable="filterable"
            type="text"
            :value="locatorUnitName"
            value-key="id"
            popper-class="filtration"
            @change="handleLocatorObject"
            size="small"
          >
            <el-option
              v-for="(unit, index) in units"
              :key="unit.id"
              :label="unit.name"
              :value="unit.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="mt-5">
        <p
          class="text-annotationColor font-SourceSansPro text-sm font-semibold"
        >
          *{{ $t('objects.locators-date-start') }}
        </p>
        <el-form-item prop="date_from">
          <el-date-picker
            class="mt-2"
            clearable="clearable"
            :picker-options="pickerOptions"
            :placeholder="$t('objects.locators-date-select')"
            popper-class="reportsDatepicker"
            type="datetime"
            v-model="locator.date_from"
            :format="dateTimeFormat"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </el-form-item>
      </div>
      <div class="mt-5">
        <p
          class="text-annotationColor font-SourceSansPro text-sm font-semibold"
        >
          *{{ $t('objects.locators-date-end') }}
        </p>
        <el-form-item prop="date_to">
          <el-date-picker
            class="mt-2"
            clearable="clearable"
            :picker-options="pickerOptions"
            :placeholder="$t('objects.locators-date-select')"
            popper-class="reportsDatepicker"
            type="datetime"
            v-model="locator.date_to"
            :format="dateTimeFormat"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </el-form-item>
      </div>
      <div class="mt-5">
        <p
          class="mb-2 text-annotationColor font-SourceSansPro text-sm font-semibold"
        >
          {{ $t('description') }}
        </p>
        <el-input
          class="locator-textarea"
          v-model="locator.description"
          :autosize="{ minRows: 2, maxRows: 4 }"
          type="textarea"
        ></el-input>
      </div>
      <div class="mt-5" v-if="locator.id">
        <p
          class="mb-2 text-annotationColor font-SourceSansPro text-sm font-semibold"
        >
          {{ $t('objects.locators-link') }}
        </p>
        <span
          class="mr-4 truncate text-base font-bold"
          style="
            font-size: 14px;
            color: #3e3e3e;
            max-width: 90%;
            display: inline-block;
          "
          >{{ locatorLinkText }}</span
        ><img
          alt=""
          class="mb-4 cursor-pointer cursor-pointer"
          src="@/assets/icons/copy.svg"
          style="width: 20px"
          @click="copyLinkToClipboard"
        />
      </div>
    </el-form>
    <div class="flex mt-4">
      <skif-button width="28" @click="createOrUpdate">{{
        title === 1 ? $t('create') : $t('change')
      }}</skif-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    title: Number,
    locatorToUpdate: {
      type: Object
    },
    admin: Boolean
  },
  data() {
    return {
      locator: {
        unit: {},
        date_from: '',
        date_to: ''
      },
      locatorUnitName: '',
      pickerOptions: {
        firstDayOfWeek: 1
      },
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      rules: {
        unit: [
          {
            required: true,
            message: this.$t('addLocatorTranslate.selectObject'),
            trigger: 'change'
          }
        ],
        date_from: [
          {
            required: true,
            message: this.$t('addLocatorTranslate.selectDateFrom'),
            trigger: 'change'
          }
        ],
        date_to: [
          {
            required: true,
            message: this.$t('addLocatorTranslate.selectDateTo'),
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('login', ['role', 'notRole']),
    ...mapGetters('units', ['units', 'unit']),
    ...mapState('login', {
      me: (state) => state.me
    }),
    locatorLink() {
      if (this.locator.id) {
        return `https://${window.location.host}/locator?id=${this.locator.id}`
      }

      return ''
    },
    locatorLinkText() {
      if (this.locator.id) {
        return `${window.location.host}/locator?id=${this.locator.id}`
      }

      return ''
    }
  },
  methods: {
    handleLocatorObject(unitId) {
      const object = this.unit(unitId)

      const { id, imei, name } = object

      this.locatorUnitName = name

      this.locator.unit = {
        id,
        imei,
        name
      }
    },
    copyLinkToClipboard() {
      navigator.clipboard.writeText(this.locatorLink)

      this.$notify.success({
        message: this.$t('objects.locator.copy-link'),
        position: 'top-left'
      })
    },
    createOrUpdate() {
      this.$refs.locatorForm.validate((valid) => {
        if (valid) {
          this.title === 1
            ? this.$store
                .dispatch('locators/CREATE', this.locator)
                .then((_) => this.$emit('close', 'locators'))
            : this.$store
                .dispatch('locators/UPDATE', this.locator)
                .then((_) => this.$emit('close', 'locators'))
        }
      })
    }
  },
  created() {
    if (this.title === 1) {
      this.locator = {
        unit: {},
        date_from: '',
        date_to: '',
        description: ''
      }
    } else {
      this.locator = { ...this.locatorToUpdate }
      this.locatorUnitName = this.locator.unit.name
    }

    if (this.admin) {
      console.log('its admin')
    }

    this.dateFormat = this.me.active_company.dateformat.key
    this.timeFormat = this.me.active_company.timeformat.key
    this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
  }
}
</script>
